import React, { createContext, useEffect, useState } from 'react';
import { apiConfig, COUNTRY_RESTRICTIONS } from 'src/config';
import axiosInstance from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';
import SplashScreen from 'src/components/SplashScreen';
import { verifyIfDashboardOperatorHasRestriction } from 'src/utils/verifyIfDashboardOperatorHasRestriction';

type SickNote = {
  id: string;
  clinicName: string;
  clinicPhone: string;
  date: string;
  daysOff: number;
  dependantCompanion: boolean;
  dependantName: string;
  dependantTaxRelated: boolean;
  dependantCompanionType: string;
  doctorName: string;
  doctorRegistry: string;
  intDiseaseClassification: string;
  file: string;
  status: 'PENDING' | 'REDO' | 'DENIED' | 'INCONSISTENT' | 'ALERT' | 'VERIFIED';
  metadata?: any;
};

type UserWithSickNotes = {
  _id: string;
  name: string;
  username: string;
  birthDate: string;
  email: string;
  document: string;
  documentNumber: string;
  sickNotes: SickNote[];
  userSelfie: string;
  createdAt: string;
  lastUpdate: string;
};

type Enterprise = {
  _id: string;
  name: string;
  features: any[];
};

type Protocol = {
  _id: string;
  name: string;
};

type DataContextData = {
  protocols: Protocol[];
  enterprises: Enterprise[];
  contactTicketsSubject: string[];
  usersWithSickNotes: any;
  isLoadingUsersByEnterpriseAndStatus: boolean;
  getUsersByEnterpriseAndStatus: (enterprise: string, status: string) => Promise<void>;
};

type DataProviderProps = {
  children: React.ReactNode;
};

//@ts-ignore
const DataContext = createContext<DataContextData>({});

export const DataProvider = ({ children }: DataProviderProps) => {
  const { user } = useAuth();
  const [isLoadingProtocolsAndEnterprises, setIsLoadingProtocolsAndEnterprises] = useState(false);
  const [isLoadingUsersByEnterpriseAndStatus, setIsLoadingUsersByEnterpriseAndStatus] = useState(false);
  const [protocols, setProtocols] = useState<Protocol[]>([]);
  const [enterprises, setEnterprises] = useState<Enterprise[]>([]);
  const [contactTicketsSubject, setContactTicketsSubject] = useState<string[]>([]);
  const [usersWithSickNotes, setUsersWithSickNotes] = useState<UserWithSickNotes[]>([]);

  const getUsersByEnterpriseAndStatus = async (enterprise: string, status: string) => {
    try {
      setIsLoadingUsersByEnterpriseAndStatus(true);
      const response = await axiosInstance.get(
        `${apiConfig.coreApiBaseUrl}/dashboard-operators/users-list-by-enterprise/${enterprise}/${status}`,
      );
      setUsersWithSickNotes(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingUsersByEnterpriseAndStatus(false);
    }
  };

  useEffect(() => {
    const getProtocolsAndEnterprises = async () => {
      try {
        setIsLoadingProtocolsAndEnterprises(true);

        const contactTicketsSubjectObject = new Set([]);

        const promises = [
          await axiosInstance.get(`${apiConfig.coreApiBaseUrl}/dashboard-operators/protocol-list`),
          await axiosInstance.get(`${apiConfig.coreApiBaseUrl}/dashboard-operators/enterprises-list`),
          await axiosInstance.get(`${apiConfig.coreApiBaseUrl}/contact-ticket-subjects`),
        ];

        const [protocolsResponse, enterprisesResponse, contactTicketsResponse] = await Promise.all(promises);

        contactTicketsResponse.data.forEach((contactTicket) => contactTicketsSubjectObject.add(contactTicket.type));
        const contactTicketSubjectArray = Array.from(contactTicketsSubjectObject);

        const hasCountryRestriction = verifyIfDashboardOperatorHasRestriction(user, COUNTRY_RESTRICTIONS);

        if (!hasCountryRestriction) {
          setProtocols(protocolsResponse.data);
          setEnterprises(enterprisesResponse.data);
          setContactTicketsSubject(contactTicketSubjectArray);

          return;
        }

        const filteredProtocols = protocolsResponse.data.filter(
          (protocol) => protocol.country === hasCountryRestriction.description,
        );

        setProtocols(filteredProtocols);
        setEnterprises(enterprisesResponse.data);
        setContactTicketsSubject(contactTicketSubjectArray);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingProtocolsAndEnterprises(false);
      }
    };
    getProtocolsAndEnterprises();
  }, [user]);

  if (isLoadingProtocolsAndEnterprises) {
    return <SplashScreen />;
  }

  return (
    <DataContext.Provider
      value={{
        protocols,
        enterprises,
        contactTicketsSubject,
        usersWithSickNotes,
        getUsersByEnterpriseAndStatus,
        isLoadingUsersByEnterpriseAndStatus,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
