import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import type { Theme } from 'src/theme';
import Account from './Account';
import axios from 'axios';
import SelectLang from 'src/components/SelectLang';
import { apiConfig } from 'src/config';
import './index.css';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //@ts-ignore
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    background: 'linear-gradient(4deg, rgba(2,0,36,1) 0%, rgba(84,193,131,1) 63%)',
  },
  stagingRoot: {
    //@ts-ignore
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: '#fc9928',
  },
  toolbar: {
    minHeight: 64,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'white',
  },
  appName: {
    marginLeft: 20,
    color: '#fff',
    fontFamily: 'Montserrat',
  },
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const [environment, setEnvironment] = useState('STAGING');

  const classes = useStyles();

  useEffect(() => {
    const fetchEnv = async () => {
      try {
        const response = await axios.get(`${apiConfig.coreApiBaseUrl}/database-configurations`);
        setEnvironment(response.data[0]?.type);
      } catch (err) {
        console.error(err);
      }
    };

    fetchEnv();
  }, []);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/" className={classes.logoWrapper}>
            <Logo width={50} height={50} />
            <Typography className={classes.appName} variant="h4">
              Dashboard
            </Typography>
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
        {/* <Box ml={4}>
          <SelectLang />
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
